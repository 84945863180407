import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from '../material-module/confirm-dialog/confirm-dialog.component';
import { Rendicontazione } from '../models/rendicontazione';
import { dataTableColumnDefinition } from '../shared/resourceful-index/dataTableColumnDefinition';
import { ResourcefulIndexComponent } from '../shared/resourceful-index/resourceful-index/resourceful-index.component';
@Component({
  selector: 'app-rendicontazione',
  templateUrl: './rendicontazione.component.html',
  styleUrls: ['./rendicontazione.component.scss']
})
export class RendicontazioneComponent implements OnInit {


  headingText = 'Attività';

  relations: string[] = ['referente1', 'referente2'];

  @ViewChild('index') index: ResourcefulIndexComponent;

  selectionMode: boolean = false;

  loading: boolean = true;

  search: any = {};

  columnDefinitions: dataTableColumnDefinition[] = [
    new dataTableColumnDefinition(
      {
        humanName: 'ID',
        internalName: 'id',
        type: 'link',
        href: (model) => model.id,
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Tipo',
        internalName: 'tipo',

      }
    ),

  ];

  constructor(public rendicontazioneService: RendicontazioneService,
    public dialog: MatDialog,
    private toast: ToastrService,
    @Optional() private dialogRef: MatDialogRef<RendicontazioneComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,) {
    if (this.data?.selectionMode) {
      this.selectionMode = true;
      this.headingText = 'Seleziona un rendicontazione';
    }
  }

  form: FormGroup;

  async ngOnInit() {
    this.form = this.fb.group({
      titolo: [],
      referente: [],
    });

    this.form.valueChanges.subscribe(newValues => this.search = newValues);

    this.loading = false;
  }

  closeDialogWithSelectedModel(model) {
    this.dialogRef?.close(model);
  }

  async updateDataSource() {
    await this.index?.updateData();
  }


  deleteFunction = async (rendicontazione: Rendicontazione) => {
    try {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Sei sicuro di voler eliminare la rendicontazione ${rendicontazione.titolo}?` } });
      dialogRef.afterClosed().subscribe(async data => {
        if (data) {
          await this.rendicontazioneService.delete(rendicontazione);
          this.toast.success('Rendicontazione eliminata.');
          this.updateDataSource();
        }
      });
    } catch (e) {
      console.error(e);
      this.toast.error("Eliminazione rendicontazione fallita.", "Errore!");
    }
  }


  add() {
    this.handleCreation(new Rendicontazione());
  }

  async handleCreation(rendicontazione?: Rendicontazione) {
    if (rendicontazione) {
      try {
        await this.rendicontazioneService.create(rendicontazione)
        this.toast.success('Rendicontazione creata!')
      }
      catch (e) {
        this.toast.error('Creazione rendicontazione fallita.', 'Errore!');
      }
      finally {
        this.updateDataSource();
      }

    }
  }

  async duplicateModel({ model, times }: { model: Rendicontazione, times: number }) {
    await this.rendicontazioneService.clone(model, times);
    this.updateDataSource();
  }

  async updateModel(model: Rendicontazione) {
    if (model.id)
      await this.rendicontazioneService.edit(model);
    else {
      await this.rendicontazioneService.create(model);
      this.updateDataSource();
    }
  }

}
