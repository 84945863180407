import { Model } from './model';

export class Rendicontazione extends Model {
    static apiUrl: string = "rendicontazione";

    nome: string;
    ID: string
    Tipo: string
    Anno: string
    CDC: string
    tipo_cs: string
    luogo_svolgimento: string
    mese: string
    start: string
    end: string
    titolo: string
    cliente: string
    preventivo: string
    referente1_id: string
    referente2_id: string
    numero_pax_delegati_o_faculty: string
    numero_pax_accreditati_ecm: string
    id_ecm: string
    stato_evento: string
    tasse_ministeriali: string
    crediti: string
    scadenza_pagamento_crediti: string
    report: string
    attestati: string
    data_spedizione: string

    getHumanIdentifier() {
        return this.titolo;
    }

    deserialize(input: any): Rendicontazione {
        let retV: Rendicontazione = new Rendicontazione();
        retV = super.deserialize(input) as Rendicontazione;
        return retV;
    }

}
